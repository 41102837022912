<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4 grey lighten-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">
          Escolha um artigo abaixo para editar ou excluir.
        </p>
        <v-btn small depressed color="fmq_gray" dark to="/artigo-criar">Novo Artigo</v-btn>
      </div>
      <ArtigosTabela
        :headers="headers"
        :itens="itens"
        :total="total"
        :pageText="pageText"
        :pageNumber="page"
        :loading="loading"
        @handleFilter="filter"
        @excluir="excluirModal"
      >
        <template v-slot:produtos>
          <v-autocomplete
            v-model="formData.produtosId"
            :items="listProdutos"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pelo produto"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="page = 1;buscar(formData)"
          ></v-autocomplete>
        </template>

        <template v-slot:autor>
          <v-text-field
            v-model="formData.autor"
            placeholder="Busque pelo autor"
            solo
            flat
            dense
            :hide-details="true"
            @input="page = 1;buscar(formData)"
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:dataPublicacao>
          <v-autocomplete
            v-model="formData.dataPublicacao"
            :items="listDatas"
            hide-no-data
            hide-selected
            item-text="value"
            item-value="value"
            placeholder="Busque pelo ano"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="page = 1;buscar(formData)"
          ></v-autocomplete>
        </template>

        <template v-slot:titulo>
          <v-text-field
            v-model="formData.titulo"
            placeholder="Busque pelo titulo"
            solo
            flat
            dense
            :hide-details="true"
            @input="page = 1;buscar(formData)"
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:tags>
          <v-autocomplete
            v-model="formData.tagsId"
            :items="listaDeTags"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pela tag"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="page = 1;buscar(formData)"
          ></v-autocomplete>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </ArtigosTabela>
      <AlertConfirmation
        :dialog="dialog"
        :dialogMessage="dialogMessage"
        @close="dialog = false"
        @accept="excluir"
      />
      <AlertSuccess
        :dialog="success"
        dialogMessage="Médico excluido com sucesso"
        @close="
          buscar(formData);
          success = false;
          clear();
        "
      />
      <AlertError :alertError="error" :messageError="messageError" />
    </div>
  </div>
</template>

<script>
import ArtigosTabela from "@/components/artigos/ArtigosTabela.vue";
import { buscarArtigos, excluirArtigo} from "@/services/artigos.js";
import { listarProdutos } from "@/services/produtos.js";
import { listarTags } from "@/services/tags.js";
export default {
  name: "Artigos",
  components: { ArtigosTabela },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: 15,
      produtosId: null,
      autor: null,
      dataPublicacao: null,
      titulo: null,
      tagsId: null,
    },
    headers: [
      { text: "Produtos", value: "produtos", sortable: false },
      { text: "Autor", value: "autor", sortable: false },
      { text: "Ano", value: "dataPublicacao", sortable: false },
      { text: "Titulo", value: "titulo" },
      { text: "Tags", value: "tags", sortable: false },
      { text: "Ações", value: "id", sortable: false },
    ],
    page: 1,
    itens: [],
    total: null,
    pageText: null,
    loading: false,
    breadcrumbs: [
      {
        text: "Artigos",
        disabled: true,
        to: "",
      },
    ],
    dialog: false,
    dialogMessage: null,
    medicoId: null,
    error: false,
    success: false,
    messageError: null,
    listProdutos: [],
    listDatas: [],
    listaDeTags: [],
  }),
  beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    if (this.$store.state.artigosSearch.length) {
      this.formData = { ...this.$store.state.artigosSearch[0] };
    }
    this.buscar(this.formData);
    this.listProducts();
    this.listTags();
    this.listDatas = this.createYearArray();
  },
  methods: {
    async buscar(payload) {
      this.itens = [];
      this.$store.dispatch("setArtigosSearch", payload);
      try {
        this.loading = true;
        const resp = await buscarArtigos(this.page, payload);
        this.itens = resp.data.data;
        this.total = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.$store.dispatch("setArtigosSearch", {});
      this.formData.produtosId = null;
      this.formData.autor = null;
      this.formData.dataPublicacao = null;
      this.formData.titulo = null;
      this.formData.tagsId = null;
      this.page = 1;
      this.buscar(this.formData);
    },
    search() {
      this.page = 1;
      this.buscar(this.formData);
    },
    filter(event) {
      this.$store.dispatch("setArtigosSearch", this.formData);
      this.formData.orderBy = event.orderBy;
      this.formData.orderSorted = event.orderSorted;
      this.formData.perPage = event.perPage;
      this.page = event.page;
      this.buscar(this.formData);
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await excluirArtigo(this.medicoId).then(() => {
          this.dialog = false;
          this.success = true;
          this.page = 1;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.dialogMessage = `Tem certeza que deseja excluir ${item.nome}?`;
      this.dialog = true;
      this.medicoId = item.id;
    },
    async listProducts() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async listTags() {
      const resp = await listarTags();
      this.listaDeTags = resp.data;
    },
    createYearArray() {
      const currentYear = new Date().getFullYear();
      const startYear = 1990;
      const yearArray = [];

      for (let year = startYear; year <= currentYear; year++) {
        yearArray.push({ value: year });
      }

      return yearArray;
    },
  },
};
</script>
